// custom variables and override bootstrap variables

$white: #fff;
$primary-light: #3c5b9a;

//== Colors

$gray-base:              #000 !default;
$gray-light:             lighten($gray-base, 54.9%); // #8c8c8c
$gray-lighter:           lighten($gray-base, 92.54%); // #ececec

$brand-primary:         #2a3768;
$brand-success:         #2ecc71;
$brand-info:            #2eabe1;
$brand-warning:         #ffcc00;
$brand-danger:          #e74c3c;

//== Scaffolding

$text-color:            $gray-base;
$link-color:            $brand-info;
$link-hover-color:      $brand-info;

//== Typography

$font-family-sans-serif:  "Museo_sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       "Kepler", Georgia, "Times New Roman", Times, serif;
$font-size-base:          16px;
$font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

$line-height-base:        1.5; // 24/16
$line-height-computed:    floor(($font-size-base * $line-height-base)); // ~24px

$font-size-h1:            floor(($font-size-base * 5.4375)); // ~87px
$font-size-h2:            floor(($font-size-base * 4.5)); // ~72px
$font-size-h3:            ceil(($font-size-base * 3)); // ~48px
$font-size-h4:            ceil(($font-size-base * 2)); // ~32px
$font-size-h5:            ceil(($font-size-base * 1.5)); // ~24px
$font-size-h6:            $font-size-base; // ~16px

$headings-font-family:    $font-family-serif;
$headings-font-weight:    900;
$headings-line-height:    1;

//== Components

$border-radius-small:       2px;

//== Buttons

$btn-font-weight:                700;

$btn-default-color:              #fff;

$btn-link-disabled-color:        $gray-light !default;

$btn-border-radius-base:         $border-radius-small;
$btn-border-radius-large:        $border-radius-small;
$btn-border-radius-small:        $border-radius-small;


//== Pagination

$pagination-color:                     $gray-light;
$pagination-border:                    $gray-light;

$pagination-hover-color:               $brand-warning;

$pagination-active-color:              $brand-warning;

// //== Pager

$pager-border-radius:                  2px;
$pager-border:                         $gray-base;
$pager-active-color:                   $gray-base;

//== Type

$hr-border:                   $gray-lighter;

//== Panels

$panel-bg:                    #fff !default;
$panel-body-padding:          20px;
$panel-heading-padding:       10px 20px !default;
$panel-footer-padding:        $panel-heading-padding !default;
$panel-border-radius:         0;

//** Border color for elements within panels
$panel-inner-border:          #e3e3e3;
$panel-footer-bg:             #fff;

$panel-default-text:          $gray-base;
$panel-default-border:        #e9e9e9;
$panel-default-heading-bg:    #fff;

//== Forms

$input-color:                    $gray-base;
$input-border:                   lighten($gray-base, 91.3%);

$input-border-radius:            3px;
$input-border-radius-large:      3px;
$input-border-radius-small:      3px;

$input-border-focus:             lighten($gray-base, 91.3%);
$input-color-placeholder:        lighten($gray-base, 69%);

$input-height-base:              48px;
$input-height-large:             44px;
$input-height-small:             40px;

$form-group-margin-bottom:       10px;

//== Media queries breakpoints

$screen-md:                  1024px;
$screen-lg:                  1250px;

//== Grid system

$grid-gutter-width:         24px;

$container-tablet:             (724px + $grid-gutter-width);
$container-desktop:            (1000px + $grid-gutter-width);
$container-large-desktop:      (1200px + $grid-gutter-width);

//== Labels

$label-default-bg:            lighten($gray-base, 91.37%);
$label-color:                 $gray-base;
$label-link-hover-color:      $gray-base;

//== Tables
//

$table-bg-accent:               #fbfbfb;
$table-bg-hover:                #f5f5f5 !default;
$table-bg-active:               $table-bg-hover !default;

$table-border-color:            lighten($gray-base, 91.37%);